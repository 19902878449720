<template>
  <div>
    <div v-if="$vuetify.breakpoint.mobile">
      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab v-if="newHomeQuests.length">
          New {{ profile.home_region.name }} Quests
        </v-tab>
        <v-tab>
          New Global Quests
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
        show-arrows
      >
        <v-tab-item v-if="newHomeQuests.length">
          <v-card>
            <v-card-text>
              <QuestTile
                v-for="quest in newHomeQuests"
                :key="quest.id"
                :quest="quest"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <v-card-text>
              <QuestTile
                v-for="quest in newAwayQuests"
                :key="quest.id"
                :quest="quest"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <v-row v-else>
      <v-col
        v-if="newHomeQuests.length"
        :cols="newAwayQuests.length ? 6 : 12"
      >
        <v-card>
          <v-card-title>
            New {{ profile.home_region.name }} Quests
          </v-card-title>
          <v-card-text>
            <QuestTile
              v-for="quest in newHomeQuests"
              :key="quest.id"
              :quest="quest"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-if="newAwayQuests.length"
        :cols="newHomeQuests.length ? 6 : 12"
      >
        <v-card>
          <v-card-title>
            New Global Quests
          </v-card-title>
          <v-card-text>
            <QuestTile
              v-for="quest in newAwayQuests"
              :key="quest.id"
              :quest="quest"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import QuestTile from '@/components/QuestTile.vue'

export default {
  components: {
    QuestTile,
  },
  data: function() {
    return {
      questLimit: 4,
      tab: null,
    }
  },
  computed: {
    ...mapState({
      quests: state => state.quests.all,
      profile: state => state.auth.profile,
    }),
    newHomeQuests() {
      if (this.profile && this.profile.home_region) {
        return this.$store.getters['quests/latestForRegion'](this.profile.home_region.id).slice(0, this.questLimit)
      }
      return []
    },
    newAwayQuests() {
      if (this.profile && this.profile.home_region) {
        return this.$store.getters['quests/latestExcludingRegion'](this.profile.home_region.id).slice(0, this.questLimit)
      }
      return this.$store.getters['quests/latest'].slice(0, this.questLimit)
    },
  },
  created: function () {
    this.$store.dispatch('quests/getAll')
  },
}
</script>
