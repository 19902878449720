<template>
  <div class="home">
    <Banners />

    <Stats />

    <NewQuests />

    <AnnouncementList />
  </div>
</template>

<script>
import { allianceAPI } from '@/api'
import AnnouncementList from '@/components/AnnouncementList.vue'
import Banners from '@/components/Banners.vue'
import NewQuests from '@/components/NewQuests.vue'
import Stats from '@/components/Stats.vue'

export default {
  name: 'Home',
  components: {
    AnnouncementList,
    Banners,
    NewQuests,
    Stats,
  },
  created: function () {
    if (this.$online) {
      this.sendPing()
    }
  },
  methods: {
    sendPing() {
      return allianceAPI.get(`/alliance/ping/?version=${this.$version}&standalone=${this.$standalone}`)
    },
  },
}
</script>
