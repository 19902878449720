<template>
  <v-row v-if="announcements.length">
    <v-col>
      <v-card>
        <v-card-title>
          Announcements
        </v-card-title>
        <v-list>
          <v-list-item
            v-for="announcement in announcements"
            :key="announcement.id"
            two-line
            @click="showAnnouncement(announcement)"
          >
            <v-list-item-content>
              <v-list-item-title>
                <div
                  v-if="announcement.published_at"
                  class="text-caption"
                >
                  {{ announcement.published_at|formatDate }}
                </div>
                {{ announcement.name }}
              </v-list-item-title>
              <!-- eslint-disable vue/no-v-html -->
              <v-list-item-subtitle v-html="announcement.text" />
              <!--eslint-enable-->
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
    <v-dialog
      v-if="announcementDetail"
      v-model="announcementDialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :max-width="$vuetify.breakpoint.thresholds.sm"
    >
      <v-card>
        <v-toolbar>
          <v-btn
            icon
            dark
            @click="announcementDialog = false"
          >
            <v-icon>
              $close
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ announcementDetail.name }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <p
            v-if="announcementDetail.published_at"
            class="text-caption"
          >
            {{ announcementDetail.published_at|formatDatetime }}
          </p>
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="announcement-content"
            v-html="announcementDetail.content"
          />
          <!--eslint-enable-->
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { allianceAPI } from '@/api'

export default {
  data: function() {
    return {
      announcements: [],
      announcementDetail: null,
      announcementDialog: false,
    }
  },
  created: function () {
    this.getAnnouncements()
  },
  methods: {
    getAnnouncements() {
      return allianceAPI.get('/alliance/announcement/')
        .then((response) => this.announcements = response.data.results)
    },
    showAnnouncement (announcement) {
      this.announcementDetail = announcement
      this.announcementDialog = true
    },
  },
}
</script>
