<template>
  <div>
    <v-row v-if="bannerContainer">
      <!-- eslint-disable vue/no-v-html -->
      <v-col v-html="bannerContainer" />
      <!--eslint-enable-->
    </v-row>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
    >
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { allianceAPI } from '@/api'

export default {
  data: function() {
    return {
      snackbar: null,
      snackbarText: null,
    }
  },
  computed: {
    ...mapState({
      banners: state => state.banners.all,
      profile: state => state.auth.profile,
    }),
    bannerContainer() {
      let inner = ''
      for (let i=0; i < this.banners.length; i++) {
        if (this.profile && this.profile.closed_banner_id_list.indexOf(this.banners[i].id) > -1) {
          continue
        }
        let content = this.banners[i].content
        // If the user is online, add a button to allow closing banners.
        if (this.$online) {
          const index = content.indexOf('>') + 1
          inner += content.slice(0, index) + `<div class="banner-close" data-banner-id=${this.banners[i].id} title="Close this banner">X</div>` + content.slice(index)
        } else {
          inner += content
        }
      }
      // If any banners were found for display, return them in a wrapped element.
      if (inner) {
        return `<div class="banner-container">${inner}</div>`
      }
      return ''
    },
  },
  created: function () {
    this.$store.dispatch('banners/getAll')
  },
  updated: function () {
    this.watchBanners()
  },
  methods: {
    watchBanners() {
      document.querySelectorAll('.banner-close').forEach(element => {
        element.addEventListener('click', this.closeBanner)
      })
    },
    closeBanner(event) {
      const bannerId = event.target.dataset.bannerId
      allianceAPI.delete(`/quest/banner/${bannerId}/`)
        .then((response) => {
          this.snackbarText = ''
          this.snackbar = false
          this.$store.dispatch('auth/updateClosedBannerList', {response})
        })
        .catch(() => {
          this.snackbarText = 'Failed to close the banner!'
          this.snackbar = true
        })
    },
  },
}
</script>
